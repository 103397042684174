import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title} || Innovator</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Esmael | Innovator",
  keyword:
    "react, html, css, javascript, typescript, nextjs, firebase, flutter, redux, tailwind, ...",
  desc: "const I am Esmael Mohammed ()=> Web, App Developer And UX/UI Designer",
};

export default Meta;
